import { useEffect, useState } from 'react';
import UploadAvatarDialog from 'components/userProfile/UploadAvatarDialog';
import { UserInfoState, loadUserInfo } from 'states/user_info';
import { useDispatch, useSelector } from 'react-redux';
import auth_api from 'api/auth_api';
import { Toast } from '@douyinfe/semi-ui';
import { useNavigate } from 'react-router-dom';
import { relPath } from 'utils/navigateHelpers';
import Title from 'utils/title';
import BilibiliAccountInfo from 'components/BilibiliAccountInfo/BilibiliAccountInfo';

export default function UserProfilePage() {
    const [allowResendEmail, setallowResendEmail] = useState<boolean>(true);
    const userInfo: UserInfoState = useSelector(
        (state: any) => state.user_info);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=> {
        loadUserInfo(dispatch, userInfo);
    }, [dispatch, userInfo]);

    const [uploadAvatarDialogVisible, setUploadAvatarDialogVisible] = useState(false);
    if (userInfo.info == null) {
        return <div className='container'>
            <h1><Title title="个人主页" /></h1>
            <p>无法获取账号信息！请先登录。</p>
        </div>;
    }
    const info = userInfo.info!;

    return <div className='container'>
        <h1><Title title="个人主页" /></h1>
        <div className='row'>
            <div className='col-md-4'>
                <div className='p-3 m-3 rounded-3 border shadow'>
                    <h2>头像</h2>
                    <button className='btn btn-primary'
                            disabled={true}
                            onClick={() => setUploadAvatarDialogVisible(true)}>
                        上传头像
                    </button>

                    <h2>用户名</h2>
                    {info.username}
                    {info.approved? "(已审核)" : "（未审核）"}

                    <h2>密码</h2>
                    <p>
                        <button className='btn btn-primary'
                            disabled={!allowResendEmail}
                            onClick={() =>
                                navigate(relPath('/auth/reset_password'))}
                        >
                            重置密码
                        </button>
                    </p>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='p-3 m-3 rounded-3 border shadow'>

                    <h2>邮箱</h2>
                    <p>
                        {info.email}
                        {info.email_verified? "（已验证）" : "（未验证）"}
                    </p>
                    <p>
                        <button className='btn btn-primary'
                            disabled={!allowResendEmail || info.email_verified}
                            onClick={() => {
                                setallowResendEmail(false);
                                auth_api.sendVerificationEmail(
                                    (_response) => {
                                        Toast.success("已发送验证邮件");
                                    });
                            }}>
                            重发验证邮件
                        </button>
                    </p>

                    <h2>Bilibili账号</h2>
                    <BilibiliAccountInfo />

                    <h2>系统信息</h2>
                    <p>
                        <b>注册时间：</b>
                        {new Date(info.sign_up_timestamp * 1000).toLocaleString()}
                    </p>
                    <p>
                        <b>最近登录：</b>
                        {new Date(info.last_login_timestamp * 1000).toLocaleString()}
                    </p>
                    {info.superuser? <b>超级管理员</b> : null}
                </div>
            </div>
        </div>

        <UploadAvatarDialog 
            visible={uploadAvatarDialogVisible}
            onUploadAvatarSuccess={() => {}}
            onCancel={() => setUploadAvatarDialogVisible(false)}
        />
    </div>;
}
