import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import { Button, TabPane, Tabs, Typography, Collapse, Row, Col, Input, Space } from '@douyinfe/semi-ui';
import { IconSearch } from '@douyinfe/semi-icons';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { getCookie } from 'utils/cookieHelper';
import './TcgContestDetailPage.css'
import TcgGamePreview from 'components/Contest/TcgGamePreview';
import { relPath } from 'utils/navigateHelpers';
import Loading from 'components/Loading/Loading';


interface Props {
}

const TcgContestDetailPage: React.FC<Props> = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const pathSpilited = location.pathname.split("/");
    const [contest, setContest] = useState<any>(null);
    const [isHost, setIsHost] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [fullMatchData, setFullMatchData] = useState<any>(null);
    const [displayData, setDisplayatchData] = useState<any>(null);

    // const token = getCookie("token");
    const username = getCookie("username");

    const { Title } = Typography;

    const getContestInfoApiCall = () => {
        axios.get(`${API_BASE_URL}/contest/tcg/info?id=${pathSpilited.slice(-1)}`, {}).then(
            (response) => {
                setContest(response.data.data);

                if ((response.data.data.hosts).includes(username)) {
                    setIsHost(true)
                }
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
    };

    const getMatchInfoApiCall = () => {
        axios.get(`${API_BASE_URL}/contest/tcg/info/matches?id=${pathSpilited.slice(-1)}`, {}).then(
            (response) => {
                // console.log(response)

                // In case 接口没有处理aggregate
                // LocaleDateString: 2022-12-13?
                const groups = response.data.matches ? response.data.matches.reduce((groups: any, match: any) => {
                    const date = new Date(match.start_time * 1000).toLocaleDateString();
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(match);
                    return groups;
                }, {}) : [];

                // Edit: to add it in the array format instead
                const groupArrays = Object.keys(groups).map((date) => {
                    return {
                        date: date,
                        matches: groups[date]
                    };
                });

                // console.log(groupArrays);
                setFullMatchData(groupArrays);
                setDisplayatchData(groupArrays);
            },
            (error) => {
                console.log("[DEBUG] TODO", error);
            }
        );
    };

    const handleSearch = () => {
        const filteredMatch = fullMatchData.filter((matchDate: any) => {
            if (searchTerm === "") {
                return matchDate
            } else {
                const result = matchDate
                const checkMatches = matchDate.matches.find((match: any) => match.player1.username === searchTerm || match.player2.username === searchTerm);
                if (checkMatches && checkMatches.length !== 0) {
                    result.matches = [checkMatches]
                    return result
                }
            }
            return matchDate;
        })
        setDisplayatchData(filteredMatch);
    }

    useEffect(() => {
        getMatchInfoApiCall();
        getContestInfoApiCall();
    });


    const getCollapseHeader = (match: any) => {
        return  (
            
            <Row gutter={0} style={{ width: '100%' }} type='flex' justify='space-around'>
                <Col span={2}></Col>
                <Col span={8} style={{ textAlign: "right" }}>
                    {match.player1.username}
                </Col>
                {match.games && match.games.length > 0 ? <Col span={2} style={{ textAlign: "center" }}>
                    {match.games.reduce((count: number, game: any) => count + (game.winner === match.player1.username ? 1 : 0), 0)}
                    :
                    {match.games.reduce((count: number, game: any) => count + (game.winner === match.player2.username ? 1 : 0), 0)}
                </Col> : <Col span={2} style={{ textAlign: "center" }}>VS</Col>}
                {/* <Col><span>:</span></Col>
                <Col span={1} style={{ textAlign: "left" }}>
                    {match.games.reduce((count: number, game: any) => count + (game.winner === match.player2.username ? 1 : 0), 0)}
                </Col> */}

                <Col span={8} style={{ textAlign: "left" }}>
                    {match.player2.username}
                </Col>
                <Col span={2}></Col>
            </Row>
        )
    }

    return (
        <>
            {contest ? <>
                <img className='contestBanner' alt="contestBanner" src={contest.bannerImg ? contest.bannerImg : "https://i.ytimg.com/vi/HIOExazTZNI/maxresdefault.jpg"} />
                <div className='container py-3'>
                    <Row type="flex" justify="start" align='middle'>
                        <Col span={20}>
                            <Title style={{ color: '#C58042' }}>{contest.name /*pathSpilited.slice(-1) */}</Title>
                        </Col>
                        {isHost && (<Col span={4}>
                            <Button theme='solid' style={{ backgroundColor: "#C58042" }} onClick={() => navigate(relPath('/contest/tcg/' + contest._id + '/admin'))}>
                                赛事管理
                            </Button>
                        </Col>)}

                    </Row>

                    <Tabs type="line" >
                        <TabPane tab="赛程" itemKey="1">
                            <>
                                <Row type="flex" justify="start" gutter={8} style={{ padding: '10px' }}>
                                    <Col xs={12} sm={8} md={8} lg={6} xl={6}   >
                                        {/* <Title heading={6}>搜索选手</Title>
                                        <div style={{ width: '10px' }} /> */}
                                        <Input placeholder='搜索选手' showClear style={{ width: '120px', border: '1px solid grey', backgroundColor: 'white' }} onChange={(e) => setSearchTerm(e)}></Input>
                                        <Button onClick={handleSearch} icon={<IconSearch style={{ color: 'white' }} />} aria-label="search" style={{ backgroundColor: '#C58042' }} />
                                    </Col>
                                    {/* {contest.stages &&
                                        <Col xs={12} sm={8} md={8} lg={6} xl={6}>
                                            <Title heading={6}>比赛阶段</Title>
                                            <Select defaultValue={(contest.stages)[0].stageName} style={{ width: 120 }}>{(contest.stages).map((stage: any, i: number) => {
                                                return (
                                                    <Select.Option value={stage.stageName}>{stage.stageName}</Select.Option>
                                                )
                                            })}</Select>

                                        </Col>} */}

                                    {/* <Col xs={24} sm={12} md={12} lg={8} xl={8} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}  >
                                <Title heading={5}>小组</Title>
                                <div style={{ width: '10px' }} />
                                <Select defaultValue="abc" style={{ width: 150 }}>
                                    <Select.Option value="abc">test1</Select.Option>
                                    <Select.Option value="aaa">test2</Select.Option>
                                    <Select.Option value="ccc">test3</Select.Option>
                                </Select>
                            </Col> */}

                                </Row>


                                {
                                    displayData && displayData.map((matchDate: any) => {
                                        return (
                                            <>
                                                <Title heading={3}>{matchDate.date}</Title>
                                                <Collapse>
                                                    {
                                                        matchDate.matches.map((match: any) => {
                                                            return (
                                                                <Collapse.Panel header={getCollapseHeader(match)} itemKey={match._id} showArrow={match.games ? true : false}>

                                                                    {
                                                                        match.games && match.games.map((game: any, index: number) => {
                                                                            return (<TcgGamePreview key={matchDate + '_' + match._id + "_" + index} gameInfo={game} p1Name={match.player1.username} p2Name={match.player2.username} />)
                                                                        })
                                                                    }

                                                                </Collapse.Panel>
                                                            )
                                                        })
                                                    }
                                                </Collapse>
                                            </>
                                        );
                                    })
                                }
                            </>
                        </TabPane>
                        <TabPane tab="当前排名" itemKey="2" >
                            开发中
                        </TabPane>
                        <TabPane tab="使用率" itemKey="3" >
                            计算中
                        </TabPane>
                    </Tabs>
                    <Space />
                </div></> : <Loading prompt={'页面加载中'} />}




        </>
    );
}

export default TcgContestDetailPage;
