import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Radio, RadioGroup, Row, Space, Table, Toast, ToastFactory, Tooltip, Typography } from '@douyinfe/semi-ui';
import { IconMore, IconVideo } from '@douyinfe/semi-icons';
import './AbyssContestDetailPage.css'
import { SortOrder } from "@douyinfe/semi-ui/lib/es/table/interface";
import abyss_api, { TeamBuild } from "api/contest/abyss_api";
import Loading from "components/Loading/Loading";
import AbyssTeamBuild from "components/Abyss/AbyssTeamBuild";
import { TQLImage } from "projectConstants";


const AbyssContestDetailPage: React.FC<{}> = () => {
    const { Text, Title } = Typography;
    const language = "zh-CN";
    const contest_id = useParams()['contest_id'];
    const [contestInfo, setContestInfoData] = useState<any>(null);
    const [contestData, setContestRecordData] = useState<any[]>();


    const [filteredData, setFilteredData] = useState<any[]>();
    const columns = [
        // {
        //     title: '标题',
        //     dataIndex: 'name',
        //     width: 400,
        //     render: (text: any, record: any, index: number) => {
        //         return (
        //             <div>
        //                 <Avatar
        //                     size="small"
        //                     shape="square"
        //                     src={record.nameIconSrc}
        //                     style={{ marginRight: 12 }}
        //                 ></Avatar>
        //                 {text}
        //             </div>
        //         );
        //     },
        // },
        {
            title: '选手',
            dataIndex: 'player_name',
            width: 100,
        },
        {
            title: 'UID',
            dataIndex: 'uid',
            align: 'center' as 'center',
            width: 50,
            render: (text: any, record: any, index: number) => {
                return <p>{text}</p>;
            },
        },
        {
            title: '成绩',
            dataIndex: 'total_time',
            defaultSortOrder: "ascend" as SortOrder,
            sorter: (a: any, b: any) => (a.total_time - b.total_time > 0 ? 1 : -1),
            width: 40,
        },
        {
            title: '配置',
            dataIndex: 'build',
            width: 300,
            render: (text: any, record: any, index: number) => {
                // console.log(text)
                return (
                    <div style={{ display: 'flex' }}>
                        {
                            text.map((teamBuild: TeamBuild, i: number) =>
                                <AbyssTeamBuild key={record.bvid + "_" + index + "_" + i} build={teamBuild} right_margin={16} />
                            )
                        }
                    </div>
                );
            },
        },
        {
            title: '参赛视频',
            dataIndex: 'bvid',
            align: 'center' as 'center',
            width: 50,
            render: (text: any, record: any, index: number) => {
                return <IconVideo size="extra-large" style={{ color: 'HotPink', cursor: "pointer" }} onClick={() => window.open(`https://www.bilibili.com/video/${text}`)} />;
            },
        },
        // {
        //     title: '成本',
        //     dataIndex: 'track',
        //     align: 'center' as 'center',
        //     width: 50,
        //     render: (text: any, record: any, index: number) => {
        //         return <p>{text}</p>;
        //     },
        // },
        // {
        //     title: '',
        //     dataIndex: 'operate',
        //     width: 50,
        //     render: () => {
        //         return <IconMore />;
        //     },
        // },
    ];





    const getAbyssContestApiCall = () => {
        if (contest_id !== undefined) {
            abyss_api.abyss_info(contest_id,
                (response) => {
                    setContestInfoData({
                        name: response.records.name,
                        tracks: response.records.tracks,
                        banner_img: response.records.banner_img,
                    })
                    setContestRecordData(response.records.all_records);
                    setFilteredData(response.records.all_records);
                },
                (error) => {
                    console.log(error)
                });
        }
    }

    const handleSelect = (track: string) => {
        // console.log(track)
        const filteredData = contestData?.filter((record: any) => {
            if (track === "all") {
                return record
            } else {
                return record.track === track
            }
        })

        setFilteredData(filteredData)
    }

    useEffect(() => {
        getAbyssContestApiCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRow = (record: any, index: number | undefined) => {
        // 给偶数行设置斑马纹
        if (index != null && index % 2 === 0) {
            return {
                style: {
                    background: 'var(--semi-color-fill-0)',
                },
            };
        } else {
            return {};
        }
    };


    return <>
        {
            contestInfo ? <>
                <div className="abyss_background" style={{ backgroundImage: `linear-gradient(transparent, white), url(${contestInfo.banner_img ? contestInfo.banner_img : TQLImage.abyss.cover})` }} />
                <div
                    style={{ height: '60px' }}
                // style={{
                //     position: 'relative',
                //     paddingTop: '30px',
                //     justifyContent: 'center',
                //     alignContent: 'center',
                //     //height: height,
                //     width: "100%",
                //     height: '100%',
                //     display: 'inline-block',
                //     padding: 20,
                //     backgroundColor: 'var(--semi-color-fill-0)'
                // }}
                />
                <div className="content_container">
                    <img className='abyss_banner' alt="abyssBanner"
                        src={contestInfo.banner_img ? contestInfo.banner_img : TQLImage.abyss.cover}
                    // src="https://blog.zh-hant.playstation.com/tachyon/sites/8/2022/02/9442026e8da0cb236954714deabc8d9761b6cfe7-scaled.jpg"
                    // src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/92e8e179-758b-423e-be37-26e31bc83a05/df3rmbm-1078783c-f20f-4d7a-b753-84bae68aab8a.png/v1/fill/w_880,h_250,q_80,strp/yae_miko_by_akshiro_df3rmbm-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MjUwIiwicGF0aCI6IlwvZlwvOTJlOGUxNzktNzU4Yi00MjNlLWJlMzctMjZlMzFiYzgzYTA1XC9kZjNybWJtLTEwNzg3ODNjLWYyMGYtNGQ3YS1iNzUzLTg0YmFlNjhhYWI4YS5wbmciLCJ3aWR0aCI6Ijw9ODgwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.ZAzKchC8CQIQaCmp2EetLchNe7rPz3OBqBxDk6BubeM"
                    />
                    <div className="board_container">

                        <Row type="flex" justify="start" align='middle'>
                            <Col span={22}>
                                <Title style={{ fontSize: '42px', color: '#C58042', paddingTop: '15px', paddingBottom: '15px' }}>{contestInfo.name}</Title>
                            </Col>
                            <Col span={2}>
                                <Button theme="solid" onClick={() => window.open(`/${language}/contest/abyss/${contest_id}/submit`, '_blank')} block>投稿</Button>
                            </Col>
                        </Row>


                        <RadioGroup
                            type='button'
                            buttonSize='large'
                            defaultValue={"all"}
                            onChange={e => handleSelect(e.target.value)}
                            style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                            <Radio key="all" value="all">全部</Radio>

                            {
                                (contestInfo.tracks) !== undefined &&
                                (contestInfo.tracks).map((track: any, i: any) => {
                                    return (
                                        <Radio key={track.name} value={track.name}>{track.name}</Radio>
                                    )
                                })
                            }


                        </RadioGroup>


                        {/* <RadioGroup type='button' buttonSize='large' defaultValue={1} style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Radio value={1}>一金</Radio>
                            <Radio value={2}>二金</Radio>
                            <Radio value={3}>无限制</Radio>
                        </RadioGroup> */}
                        <Table columns={columns} key={"_id"} dataSource={filteredData} onRow={handleRow} pagination={false} />
                    </div>
                </div>
            </> : <Loading prompt={'页面加载中'} />
        }
    </>
}


export default AbyssContestDetailPage;